@use 'common'
@import url('@lmc-eu/cookie-consent-manager/LmcCookieConsentManager.css')

body
    --lmcccm-p-font-family: var(--lmcccm-font-family, 'Open sans', sans-serif)
    --lmcccm-p-bg: var(--lmcccm-bg, #fff)
    --lmcccm-p-text: var(--lmcccm-text, #{common.$color-text})

    --lmcccm-p-modal-bg: var(--lmcccm-modal-bg, #fff)
    --lmcccm-p-modal-text: var(--lmcccm-modal-text, #{common.$color-text})
    --lmcccm-p-modal-section-border: var(--lmcccm-p-modal-section-border, #{common.$color-text})

    // links
    --lmcccm-p-link-text: var(--lmcccm-link-text, #{common.$color-text})
    --lmcccm-p-link-hover-text: var(--lmcccm-link-hover-text, #{common.$color-black})
    --lmcccm-p-link-active-text: var(--lmcccm-link-active-text, #{common.$color-black})

    // buttons
    --lmcccm-p-base-font-size: var(--lmcccm-base-font-size, 1em)
    --lmcccm-p-btn-font-weight: var(--lmcccm-btn-font-weight, 600)
    --lmcccm-p-btn-border-radius: var(--lmcccm-btn-border-radius, 0.25em)

    // primary
    --lmcccm-p-btn-primary-bg: var(--lmcccm-btn-primary-bg, #{common.$color-violet-4})
    --lmcccm-p-btn-primary-text: var(--lmcccm-btn-primary-text, #fff)
    --lmcccm-p-btn-primary-border: var(--lmcccm-btn-primary-border, #{common.$color-violet-4})
    --lmcccm-p-btn-primary-hover-border: var(--lmcccm-btn-primary-hover-border, #22849d)
    --lmcccm-p-btn-primary-hover-bg: var(--lmcccm-btn-primary-hover-bg, #22849d)
    --lmcccm-p-btn-primary-hover-text: var(--lmcccm-btn-primary-hover-text, #fff)
    --lmcccm-p-btn-primary-active-border: var(--lmcccm-btn-primary-active-border, #22849d)
    --lmcccm-p-btn-primary-active-bg: var(--lmcccm-btn-primary-active-bg, #22849d)
    --lmcccm-p-btn-primary-active-text: var(--lmcccm-btn-primary-active-text, #fff)

    // secondary
    --lmcccm-p-btn-secondary-bg: var(--lmcccm-btn-secondary-bg, transparent)
    --lmcccm-p-btn-secondary-text: var(--lmcccm-btn-secondary-text, #{common.$color-violet-4})
    --lmcccm-p-btn-secondary-border: var(--lmcccm-btn-secondary-border, #{common.$color-violet-4})
    --lmcccm-p-btn-secondary-hover-border: var(--lmcccm-btn-secondary-hover-border, #{common.$color-violet-4})
    --lmcccm-p-btn-secondary-hover-bg: var(--lmcccm-btn-secondary-hover-bg, rgba(0, 0, 0, 0.03))
    --lmcccm-p-btn-secondary-hover-text: var(--lmcccm-btn-secondary-hover-text, #{common.$color-violet-4})
    --lmcccm-p-btn-secondary-active-border: var(--lmcccm-btn-secondary-active-border, #{common.$color-violet-4})
    --lmcccm-p-btn-secondary-active-bg: var(--lmcccm-btn-secondary-active-bg, transparent)
    --lmcccm-p-btn-secondary-active-text: var(--lmcccm-btn-secondary-active-text, #{common.$color-violet-4})

#cc_div
    .title
        font-family: var(--lmcccm-p-font-family)
        font-size: 1em
        margin-bottom: 0

#s-bl .b-acc .p
    color: common.$color-text

// #cm,
// #s-hdr, #s-inr, #s-bns
// 	backdrop-filter: blur(em(10px)) // https://caniuse.com/#search=backdrop-filter
