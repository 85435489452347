@use 'common'
@import 'react-toastify/dist/ReactToastify.css'
@import 'cookieBar'

:root
	--common-edge-gap: 2rem
	--common-content-width-small: 53.75rem
	--common-content-width-medium: 67.5rem
	--common-content-width-normal: 81.25rem
	--common-content-width-wide: 90rem
	--background-color: #FFFFFF
	--text-color: #000000
	overflow-y: scroll

	--Header-height: 5.9375rem
	--Header-wrapper-inlineSpacing: var(--common-edge-gap)
	--Header-wrapper-bottomSpacing: 1rem
	--Header-link-blockSpacing: 0.5rem
	--HeaderNestedLinks-linkToBorder-blockSpacing: calc(var(--Header-wrapper-bottomSpacing) + var(--Header-link-blockSpacing))

	--content-gap-default: 5rem
	--content-gap-small: 2rem
	--content-smallSpacing: 2rem

	@media (min-width: common.$break48)
		--content-gap-small: 3.125rem
		--content-gap-default: 7rem
		--Header-height: 7rem

	@media (min-width: 54.125rem)
		--Header-height: 5.5rem

	// https://www.npmjs.com/package/shared-loading-indicator
	--ProgressLoadingIndicator-color: #{common.$color-primary}

	@media (min-width: 40rem)
		--common-edge-gap: 2rem

	@media (min-width: 81.25rem)
		--Header-wrapper-inlineSpacing: 4.375rem

html
	overflow-y: scroll
	min-height: 100vh
	scroll-behavior: smooth
	min-height: -webkit-fill-available

body
	+common.body1

	margin: 0
	font-family: var(--font-body)
	font-smoothing: antialiased
	-webkit-font-smoothing: antialiased
	background-color: common.$color-white
	color: common.$color-text

	.hidingHeader
		--hidingHeader-bounds-height-override: max(var(--hidingHeader-bounds-height), calc(var(--Animation-height) - 5vh))
		height: var(--hidingHeader-bounds-height-override)
		margin-bottom: calc(var(--hidingHeader-height) - var(--hidingHeader-bounds-height-override))
	@media print
		.hidingHeader
			height: auto
			margin-bottom: 0

		.hidingHeader-in
			position: static

	b
		font-weight: 600

*, *::before, *::after
	box-sizing: border-box

button
	cursor: pointer
	border: unset

h1, h2, h3, h4, h5, h6
	+common.heading
	color: common.$color-black
	font-family: common.$albra-semi-bold-font

h1
	+common.heading1

h2
	+common.heading2

h3
	+common.heading3

h4
	+common.heading4

h5
	+common.heading5

input
	font-family: sans-serif

a
	color: common.$color-primary
	transition: color .3s ease-out
	text-decoration: none

	&:hover, &:focus-visible
		color: common.$color-violet-3

input:focus,
select:focus,
textarea:focus,
button:focus
	outline: common.$color-violet-2 solid 2px
